import React, {useEffect, useState} from 'react';
import {Echarts, Flex, Text} from 'components';
import {Color} from 'styles';
import {Card, ChartLegend, EchartsDefaultOption} from '../common';
import Date from '../common/date';
import {Props} from '../template_factory';
import {Chart, useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {blueGrayYellow30, blueGrayYellow60} from 'styles/color';
import {addComma, uuid} from 'lib/utils';
import {CVR1, CVR2, CVR3} from '../../../constant/temp';
import {useSst} from 'context';
import {ValueUnit} from '../common/value-unit-list';

const Contents = ({item}: Props): JSX.Element => {
  // const {chart, valueUnit} = useCardsCardNoQuery(item?.cardNo);
  const [options, setOptions] = useState<echarts.EChartsOption>(EchartsDefaultOption({}));

  const {sst} = useSst();
  const [valueUnit, setValueUnit] = useState<ValueUnit[]>();
  const [chartData, setChartData] = useState<Chart[]>(CVR1);

  useEffect(() => {
    setValueUnit([
      {
        label: 'CVR',
        value: sst?.name === 'Bank1' ? 2 : sst?.name === 'Bank2' ? 1.3 : 1.9,
        unit: 'kWh'
      }
    ]);
    setChartData(sst?.name === 'Bank1' ? CVR1 : sst?.name === 'Bank2' ? CVR2 : CVR3);
  }, [sst]);

  useEffect(() => {
    if (chartData) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        series: chartData.map((item, index) => {
          return {
            type: 'line',
            data: item.data,
            name: item.label,
            showSymbol: false,
            itemStyle: {
              color: blueGrayYellow30[index] as string
            },
            areaStyle: {
              color: blueGrayYellow60[index] as string
            }
          };
        }),
        grid: {top: '40px', left: '60px', right: '20px', bottom: '30px'},
        boundaryGap: ['50%', '50%'],
        xAxis: {
          show: false,
          data: Array.from({length: 24}, (_, index) => {
            return `${index.toString().padStart(2, '0')}`;
          })
        }
        // xAxis: {
        //   type: 'category',
        //   data: Array.from({length: 24}, (_, index) => {
        //     return `${index.toString().padStart(2, '0')}`;
        //   }),
        //   name: '시',
        //   nameLocation: 'end',
        //   nameTextStyle: {
        //     fontSize: 10,
        //     padding: [0, 0, 5, -20],
        //     verticalAlign: 'bottom'
        //   },

        //   axisLabel: {
        //     formatter: function (value: string) {
        //       return `${value}`;
        //     },
        //     align: 'right',
        //     interval: 1
        //   }
        // }
      }));
    }
  }, [chartData]);

  return (
    // <Card title={item?.title}>
    <Card title={'CVR factor 현황'}>
      <Flex.Column gap={12}>
        {/* 차트 */}
        {options && <Echarts options={options} width={'100%'} height={140} />}

        {/* 날짜 */}
        <Date fomat={' 01일 ~ 30시 까지'} />

        <Flex.Center>
          <Text label={'평균 CVR factor'} size={12} weight={600} style={{marginRight: 10}} />
          <Text
            label={addComma({value: (valueUnit && valueUnit[0].value) || 0})}
            color={Color.blue30}
            size={20}
            weight={700}
          />
        </Flex.Center>

        {/* 범례 */}
        <Flex.Center style={{gap: 20}}>
          {chartData?.map((item, index) => {
            return <ChartLegend key={uuid()} color={blueGrayYellow30[index] as string} title={item.label} />;
          })}
        </Flex.Center>
      </Flex.Column>
    </Card>
  );
};

export default Contents;
