import {useQuery} from '@tanstack/react-query';
import {IconArrowTowWay} from 'assets/svg';
import {Dropdown, Text} from 'components';
import {useSst, useToast, useUserMe} from 'context';

import {styled} from 'styled-components';
import {Layout} from '../api/querykey';
import {getBank} from '../api';
import {Item} from '../api/type';
import {useEffect} from 'react';

const Sst = () => {
  const {data} = useUserMe();
  const {sst, setSst} = useSst();
  const {setMessage} = useToast();

  const {data: banks} = useQuery({
    queryKey: [Layout.BANK, data],
    queryFn: () => getBank(data?.siteId),
    enabled: !!data?.siteId
  });

  //  변경시 toast
  const onClickSelect = (option?: Item) => {
    if (option)
      setMessage(
        <ToastWrap>
          <IconArrowTowWay />
          <Text label={`${option.name}로 변경되었습니다.`} />
        </ToastWrap>
      );
  };

  useEffect(() => {
    if (banks && banks?.length > 1) {
      setSst(banks[0]);
    }
  }, [banks]);

  return (
    <Content>
      {banks && banks?.length > 1 && (
        <Dropdown options={banks} selectedOption={sst} onSelect={setSst} onClick={onClickSelect} />
      )}
    </Content>
  );
};
export default Sst;

const Content = styled.div`
  margin-right: 30px;
`;

const ToastWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
