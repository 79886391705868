/**
 * @name Util
 * @description
 */
import {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Text} from 'components';
import {Color} from 'styles';
import {IconReload} from 'assets/svg';
import {useQueryClient} from '@tanstack/react-query';
import {useCardsLayoutQuery} from 'pages/dashboard/hooks/useCardLayout';
import {dashboardQueryKey} from 'pages/dashboard/api/querykey';
import {useSst} from 'context';

const UtilTime: React.FC = () => {
  const reloadTime = 60;
  const {sst} = useSst();
  const {data} = useCardsLayoutQuery({bankNo: sst?.value as number});
  const [time, setTime] = useState<number>(reloadTime);
  const queryClient = useQueryClient();

  const reload = useCallback(() => {
    setTime(reloadTime);

    data?.forEach((item: any) => {
      const cardNo = item.cardNo;
      if (!cardNo) return;
      queryClient.invalidateQueries({
        queryKey: [dashboardQueryKey.CARD_NO, cardNo]
      });
    });
  }, [data, queryClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((currentTime) => {
        const temp = currentTime - 1;
        if (temp <= 0) {
          reload();
          return reloadTime;
        }
        return temp;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [reload]);

  return (
    <Content
      onClick={() => {
        reload();
      }}>
      <IconReload fill={Color.blue30} />
      <Text label={`${time}s`} mode="Body_MM" color={Color.blue30} style={{marginLeft: 4}} />
    </Content>
  );
};
export default UtilTime;

const Content = styled.button`
  display: flex;
  width: 55px;
  margin-right: 16px;
  align-items: center;
  flex-direction: row;
`;
