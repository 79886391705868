/**
 * @name App
 * @returns
 */
import {useAuthStore, useToast} from 'context';
import {useEffect} from 'react';
import {SESSION_STORAGE} from 'lib/const';
import {useRoutes} from 'lib/hooks';
import {MENU} from 'config';
import styled from 'styled-components';
import {Color, desktop_max_width} from 'styles';
import {AppType} from 'types';
// contents
import Header from './header';
import {Gnb} from './gnb';
import {Toast} from 'components';

const App = ({style, padding, children}: AppType): JSX.Element => {
  const {navigate} = useRoutes();
  // store
  const {accessToken} = useAuthStore();
  const {message} = useToast();
  // refreshLogin
  async function refreshLogin() {
    const _token = await sessionStorage.getItem(SESSION_STORAGE.TOKEN);
    // log(`====[refreshLogin]====`, 'color:tomato;font-size:10px;');
    // log(`${_token}`, 'color:teal;font-size:10px;');
    if (!_token) {
      navigate(MENU.SIGN_IN);
    }
  }
  // useEffect
  useEffect(() => {
    if (!accessToken) refreshLogin();
  }, [accessToken]);

  return (
    <Content style={style} padding={padding}>
      {/*==왼쪽GNB영역==*/}

      <Gnb />

      <main>
        <Header />
        {/*==컨텐츠영역==*/}
        <div className="children">{children}</div>
        {/* <Footer /> */}
      </main>
      {/* modal-layer-popup */}

      {/* Toast */}
      {message && <Toast />}
    </Content>
  );
};

export default App;

const Content = styled.article<AppType>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #f2f4f9;
  > main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-left: 70px;
    /* overflow-y: auto; */
    /* height: calc(100% - 200px); */
    /* background-color: #f2f4f9; */
    .children {
      display: flex;
      flex: 1;
      overflow-y: auto;
      min-height: 60vh;
      padding: ${({padding}) =>
        typeof padding === 'number' ? `${padding}px` : padding?.replace(/[^0-9\s]/g, '').trim() || '16px 24px 35px'};
      /* padding: 15px 24px; */

      /* background-color: ${Color.white}; */
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
  }
  @media all and (max-width: ${desktop_max_width}) {
    .children {
      overflow-y: visible;
    }
  }
`;
