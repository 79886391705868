/**
 * @name 월유효전력현황

 * @id 414
 */
import React, {useEffect, useState} from 'react';
import {Echarts, Flex, Text} from 'components';
import {Color} from 'styles';
import {Card, ChartLegend, EchartsDefaultOption, ValueUnit} from '../common';
import {IconTime} from 'assets/svg';
import dayjs from 'dayjs';
import {Props} from '../template_factory';
import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {useSst, useUserMe} from 'context';
import {
  lineArerData11,
  lineArerData12,
  lineArerData13,
  lineArerData21,
  lineArerData22,
  lineArerData23
} from 'pages/dashboard/constant/temp';

const Contents = ({item}: Props): JSX.Element => {
  const {isLoading, data: card} = useCardsCardNoQuery(item?.cardNo);
  const [options, setOptions] = useState<echarts.EChartsOption>(EchartsDefaultOption({}));

  // 임시용
  const [isSst, setIsSst] = useState<boolean>(false);
  const {data} = useUserMe();
  const {sst} = useSst();

  useEffect(() => {
    if (card && !isLoading) {
      const temp0: any = card[0].data[0];
      const temp1: any = card[1].data[0];

      data?.siteId === 'SST' ? setIsSst(true) : setIsSst(false);

      setOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            name: card[0].name,
            data: isSst
              ? sst?.name === 'Bank1'
                ? lineArerData11
                : sst?.name === 'Bank2'
                  ? lineArerData12
                  : lineArerData13
              : temp0.data,
            type: 'line',
            color: Color.yellow30,
            showSymbol: false,
            areaStyle: {
              color: Color.yellow60 // 두 번째 라인에 대해 배경 색상을 지정
            }
          },
          {
            name: card[1].name,
            data: isSst
              ? sst?.name === 'SST1'
                ? lineArerData21
                : sst?.name === 'SST2'
                  ? lineArerData22
                  : lineArerData23
              : temp1.data,
            type: 'line',
            showSymbol: false,
            color: Color.blue30,
            areaStyle: {
              color: Color.blue60 // 두 번째 라인에 대해 배경 색상을 지정
            }
          }
        ],
        grid: {top: '40px', left: '60px', right: '20px', bottom: '30px'},
        boundaryGap: ['50%', '50%'],
        xAxis: {
          type: 'category',
          data: Array.from({length: 31}, (_, index) => {
            return `${index.toString().padStart(2, '0')}`;
          }),
          name: '일',
          nameLocation: 'end',
          nameTextStyle: {
            fontSize: 10,
            padding: [0, 0, 5, -20],
            verticalAlign: 'bottom'
          },

          axisLabel: {
            formatter: function (value: string) {
              return `${value}`;
            },
            align: 'right',
            interval: 1 // 데이터 포인트 수에 따라 조정
            // margin: 10,
          }
        }
      }));
    }
  }, [card, isLoading, sst, isSst]);

  return !isLoading && card ? (
    <Card title={item?.title}>
      {/* <Debug data={card} /> */}
      {/*======================== 차트영역 */}
      {options && <Echarts options={options} width={'100%'} height={150} />}
      {/*======================== 하단타이틀 */}
      <Flex.Center>
        <IconTime />
        <Text
          mode="Title_SB"
          style={{marginLeft: 5}}
          label={`${dayjs(card[1].startDate).format('DD')}일 부터 ~ ${dayjs(card[1].endDate).format('DD')}일 까지`}
          color={Color.gray50}
        />
      </Flex.Center>
      <Flex.SpaceBetween style={{height: 42}}>
        <Flex.Center>
          <Text style={{marginRight: 16}} label={card[2].name} mode="Caption_SB" color={Color.gray50} />
          <ValueUnit
            value={
              isSst
                ? sst?.name === 'Bank1'
                  ? 158212
                  : sst?.name === 'Bank2'
                    ? 142534
                    : 122542
                : Number(card[2].data[0])
            }
            valueColor={Color.yellow30}
            unit={card[2].unit}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </Flex.Center>
        <Flex.Center>
          <Text style={{marginRight: 16}} label={card[3].name} mode="Caption_SB" color={Color.gray50} />
          <ValueUnit
            value={
              isSst ? (sst?.name === 'Bank1' ? 118212 : sst?.name === 'Bank2' ? 94542 : 77834) : Number(card[3].data[0])
            }
            valueColor={Color.blue30}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            unit={card[3].unit}
          />
        </Flex.Center>
      </Flex.SpaceBetween>

      {/*========================  범례 */}
      <Flex.Center>
        <ChartLegend color={Color.yellow30} title={card[0].name} style={{marginRight: 20}} />
        <ChartLegend color={Color.blue30} title={card[1].name} />
      </Flex.Center>
    </Card>
  ) : (
    <React.Fragment />
  );
};

export default Contents;
