import {useQuery} from '@tanstack/react-query';
import {fetchData} from 'api';
// types
import {ResponseType} from 'types';
import {API} from 'config';
import {CardsLayout} from '../api/type';
import {dashboardQueryKey} from '../api/querykey';

/**
 * @name 전체레이아웃조회
 * @description 사이트별 전체 카드 레이아웃을 조회합니다
 */

export const useCardsLayoutQuery = (params: {bankNo?: number}) => {
  const {isLoading, data} = useQuery({
    queryKey: [dashboardQueryKey.CARDS_LAYOUT, params],
    queryFn: async (): Promise<CardsLayout[]> => {
      const res: ResponseType<CardsLayout[]> = await fetchData(`${API}/cards/layout`, {
        method: 'GET',
        params: params
      });
      return res.data;
    }
  });

  return {
    isLoading: isLoading,
    data: data
  };
};
