/**
 * @name Main
 * @description
 */
import React, {Fragment} from 'react';

import {desktop_max_width} from 'styles';
import {uuid} from 'lib/utils';
import {styled} from 'styled-components';
import {TemplateFactory} from './template_factory';
import {useCardsLayoutQuery} from 'pages/dashboard/hooks/useCardLayout';
import Custom from './card/custom';
import {useSst} from 'context';

const Contents: React.FC = () => {
  const {sst} = useSst();
  // 대시보드 레이아웃
  const {data} = useCardsLayoutQuery({bankNo: sst?.value as number});

  return (
    <Content>
      {data &&
        data.map((item) => {
          return (
            <Fragment key={uuid()}>
              {item.templateName?.includes('CUSTOM') ? <Custom item={item} /> : TemplateFactory.create({item})}
            </Fragment>
          );
        })}
    </Content>
  );
};

export default Contents;

const Content = styled.article`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 320px;
  gap: 20px;
  padding: 20px 10px;

  @media all and (max-width: ${desktop_max_width}) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    padding: 0;
  }
`;
