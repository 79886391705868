/**
 * @name 로그아웃
 * @description
 */
import {MENU} from 'config';
import {useSst} from 'context';
import {useRoutes} from 'lib/hooks';
import React, {useEffect} from 'react';
import {App} from 'template';
// controllers
// views

const SignOut: React.FC = () => {
  const {navigate} = useRoutes();
  const {setSst} = useSst();
  useEffect(() => {
    setSst(undefined);
    // sign out 세션 삭제
    sessionStorage.clear();
    navigate(MENU.SIGN_IN);
  }, [navigate]);

  return (
    <App>
      {/*================== Header ========*/}
      {/*================== Footer ========*/}
    </App>
  );
};
export default SignOut;
