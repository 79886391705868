import {Item} from 'template/app/api/type';
import {create} from 'zustand';

type UseSstType = {
  sst: Item | undefined;
  setSst: (name: Item | undefined) => void;
};

export const useSst = create<UseSstType>((set) => ({
  sst: undefined,
  setSst: (name: Item | undefined) => set({sst: name})
}));
