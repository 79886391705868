import {create} from 'zustand';

type UseToastType = {
  message?: React.ReactNode | string;
  setMessage: (name: React.ReactNode | string | undefined) => void;
};

export const useToast = create<UseToastType>((set) => ({
  message: undefined,
  setMessage: (text: React.ReactNode | string | undefined) => set({message: text})
}));
