import {useToast} from 'context';
import {useEffect} from 'react';
import styled from 'styled-components';
import {Color} from 'styles';

const Toast = () => {
  const {message, setMessage} = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage(undefined);
    }, 2000);

    return () => clearTimeout(timer);
  }, [message]);

  return <Content>{message}</Content>;
};

export default Toast;

const Content = styled.div`
  position: fixed;
  top: 80px;
  left: 50%;
  max-width: 400px;
  padding: 14px 20px;
  background-color: ${Color.white};
  border: 1px solid ${Color.blue30};
  border-radius: 10px;
  font-weight: 700;
  white-space: pre-wrap;
  word-wrap: break-word;
  transform: translateX(-50%);
`;
