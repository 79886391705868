import {Flex, Text} from 'components';
import {styled} from 'styled-components';
import {Color} from 'styles';
import Table from '../common/table';
import {useState, Fragment, useEffect} from 'react';
import {useForm} from 'react-hook-form';

type SettingForm = {
  targetVolt: number;
  minimumVolt: number;
};

const Index = () => {
  const headers = [{label: 'NO'}, {label: '설비 모델명'}, {label: '최소운전전압'}];
  const [active, setActive] = useState('추천');

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm<SettingForm>({
    mode: 'onSubmit'
  });

  const onSubmit = (data: SettingForm) => {
    console.log('data', data);
  };

  useEffect(() => {
    if (active === '추천') reset();
  }, [active]);

  return (
    <Flex.Center flexDirection={'column'} style={{paddingTop: 20, gap: 20}}>
      <Flex.Start width={'1000px'}>
        <Text label={'설정'} weight={700} size={32} />
      </Flex.Start>
      <Card>
        {/* 타이틀 */}
        <Flex.SpaceBetween style={{padding: '0px 26px', height: 50}}>
          <Text label={'SST'} weight={700} size={16} />
          <SwitchWrap>
            {['추천', '고정'].map((label, index) => (
              <Fragment key={label}>
                <SwitchButton onClick={() => setActive(label)} $active={label === active}>
                  {label}
                </SwitchButton>
                {index === 0 && <VerticalLine />}
              </Fragment>
            ))}
          </SwitchWrap>
        </Flex.SpaceBetween>
        <Line />
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* 전압 */}
          <Flex.SpaceBetween>
            <Box>
              <Text label={'전격 전압'} weight={700} size={16} />
              <BoxText>
                <Text label={'380'} color={Color.blue30} weight={700} size={30} />
                <Text label={'V'} weight={700} size={20} style={{marginLeft: 12}} />
              </BoxText>
            </Box>
            <Box>
              <Text label={'목표 전압'} weight={700} size={16} />
              <BoxTextWrap>
                <BoxText>
                  {active === '추천' ? (
                    <Text label={'380'} color={Color.blue30} weight={700} size={30} />
                  ) : (
                    <Input
                      placeholder={'전압 입력'}
                      $error={!!errors?.targetVolt}
                      {...register('targetVolt', {
                        onChange: (e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        },
                        required: '필수값 입니다.',
                        max: {value: 400, message: '350V~400V 사이로 입력해주세요.'},
                        min: {value: 350, message: '350V~400V 사이로 입력해주세요.'}
                      })}
                    />
                  )}
                  <Text label={'V'} weight={700} size={20} style={{marginLeft: 12}} />
                </BoxText>
                {errors?.targetVolt && (
                  <Text
                    label={errors?.targetVolt?.message}
                    weight={500}
                    size={10}
                    color={Color.red30}
                    style={{width: '100%', marginTop: 3}}
                  />
                )}
              </BoxTextWrap>
            </Box>
            <Box $last={true}>
              <Text label={'최소 운전 전압'} weight={700} size={16} />
              <BoxTextWrap>
                <BoxText>
                  {active === '추천' ? (
                    <Text label={'380'} color={Color.blue30} weight={700} size={30} />
                  ) : (
                    <Input
                      placeholder={'전압 입력'}
                      $error={!!errors?.minimumVolt}
                      {...register('minimumVolt', {
                        onChange: (e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        },
                        required: '필수값 입니다!!!.',
                        max: {value: 400, message: '350V~400V 사이로 입력해주세요.'},
                        min: {value: 350, message: '350V~400V 사이로 입력해주세요.'},
                        maxLength: {value: 3, message: '3글자 이상 x.'}
                      })}
                    />
                  )}
                  <Text label={'V'} weight={700} size={20} style={{marginLeft: 12}} />
                </BoxText>

                {errors?.minimumVolt && (
                  <Text
                    label={errors?.minimumVolt?.message}
                    weight={500}
                    size={10}
                    color={Color.red30}
                    style={{width: '100%', marginTop: 3}}
                  />
                )}
              </BoxTextWrap>
            </Box>
          </Flex.SpaceBetween>
          <Line />

          {/* 설비 리스트 */}
          <ListWrap>
            <Text label={'설비 리스트'} weight={700} size={16} />
            <TableWrap>
              <Table headers={headers}>
                {Array.from({length: 10}, (_, index) => (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>Model_name_{index}</td>
                    <td>
                      <Text label={'380'} weight={400} size={14} />
                      <Text label={'V'} weight={400} size={14} style={{marginLeft: 5}} />
                    </td>
                  </tr>
                ))}
              </Table>
            </TableWrap>
          </ListWrap>
          <Line />

          {/* 버튼 */}
          <ButtonWrap>
            <Button type="button" $type={'cancel'} onClick={() => reset()}>
              취소
            </Button>
            <Button type="submit" $type={'summit'}>
              저장
            </Button>
          </ButtonWrap>
        </form>
      </Card>
    </Flex.Center>
  );
};

export default Index;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  overflow: hidden;
  border-radius: 10px;
  background-color: ${Color.white};
  border: 1px solid ${Color.gray70};
  box-shadow: 3px 3px 50px 0px rgba(0, 0, 0, 0.1);
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Color.gray70};
`;
const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${Color.blue30};
`;

const Box = styled.div<{$last?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 26px;
  width: 100%;
  height: 90px;
  border-right: ${(props) => (props.$last ? undefined : `1px solid ${Color.gray70}`)};
`;

const BoxTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 175px;
  height: 100%;
`;

const BoxText = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 175px;
  height: 100%;
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 460px;
  padding: 20px 26px;
`;

const TableWrap = styled.div`
  width: 100%;
  height: 380px;
  overflow-y: auto;
  border-top: 1px solid ${Color.blue50};
`;

const ButtonWrap = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px 26px;
  gap: 10px;
`;

const Button = styled.button<{$type: string}>`
  background-color: ${(props) => (props.$type === 'cancel' ? Color.gray80 : Color.blue30)};
  color: ${(props) => (props.$type === 'cancel' ? Color.gray60 : Color.white)};
  height: 40px;
  width: 90px;
  border-radius: 6px;
`;

const Input = styled.input<{$error: boolean}>`
  font-size: 18px;
  width: 150px;
  height: 50px;
  background-color: ${Color.sepia100};
  border: 1px solid ${(props) => (props.$error ? Color.red30 : Color.sepia90)};
  border-radius: 4px;
  text-align: center;
  outline: none;
`;

const SwitchWrap = styled.div`
  display: flex;
  border: 1px solid ${Color.blue30};
  border-radius: 4px;
  width: 140px;
  height: 30px;
`;

const SwitchButton = styled.button<{$active: boolean}>`
  flex: 1;
  padding: 8px 0;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.$active ? `${Color.blue90}` : 'white')};
  color: ${Color.blue30};
  font-size: 14px;
  font-weight: ${(props) => (props.$active ? 700 : 400)};
  cursor: pointer;
`;
