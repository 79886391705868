import {Item, Gnb} from './type';
import {ResponseType} from 'types';
import {fetchData} from 'api/fetch';
import {API} from 'config';

/**
 * @name GNB 데이터
 * @description GNB몰록 가져오기
 */
export const getGnb = async (): Promise<Gnb[]> => {
  const res: ResponseType<Gnb[]> = await fetchData(`${API}/menu`, {method: 'GET'});
  return res.data;
};

/**
 * @name Bank 데이터
 * @description GNB몰록 가져오기
 */
export const getBank = async (siteId?: string): Promise<Item[]> => {
  const res: ResponseType<Item[]> = await fetchData(`${API}/sites/${siteId}/banks`, {method: 'GET'});
  return res.data;
};
